// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UpdateBook_Container__v8YGj {\n    background-color: #FFFFFF;\n    margin: auto;\n    border-radius: 7px;\n    box-shadow:  0 4px 34px rgba(0, 0, 0, 0.07);\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-block: 80px;\n}\n\n.UpdateBook_Container__v8YGj p {\n    font-family: \"Libre Baskerville\", serif;\n    font-size: 16px;\n}", "",{"version":3,"sources":["webpack://./src/pages/updateBook/UpdateBook.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,2CAA2C;IAC3C,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,uCAAuC;IACvC,eAAe;AACnB","sourcesContent":[".Container {\n    background-color: #FFFFFF;\n    margin: auto;\n    border-radius: 7px;\n    box-shadow:  0 4px 34px rgba(0, 0, 0, 0.07);\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-block: 80px;\n}\n\n.Container p {\n    font-family: \"Libre Baskerville\", serif;\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "UpdateBook_Container__v8YGj"
};
export default ___CSS_LOADER_EXPORT___;
