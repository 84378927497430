// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_Footer__WiYT0 {\n    margin-top: auto;\n    width: 100%;\n    background-color: #FFFFFF;\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    font-family: 'DM Sans', sans-serif;\n    font-size: 14px;\n    color: #2C1810\n}\n\n.Footer_MapBlock__-GYmU {\n    display: flex;\n    margin-block: 16px;\n    align-items: center;\n\n}\n.Footer_MapBlock__-GYmU a {\n    color: #796157;\n}\n.Footer_MapBlock__-GYmU img {\n    margin-right: 16px;\n}\n.Footer_MapLink__cf2lU {\n\n}", "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,kCAAkC;IAClC,eAAe;IACf;AACJ;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;;AAEvB;AACA;IACI,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;;AAEA","sourcesContent":[".Footer {\n    margin-top: auto;\n    width: 100%;\n    background-color: #FFFFFF;\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    font-family: 'DM Sans', sans-serif;\n    font-size: 14px;\n    color: #2C1810\n}\n\n.MapBlock {\n    display: flex;\n    margin-block: 16px;\n    align-items: center;\n\n}\n.MapBlock a {\n    color: #796157;\n}\n.MapBlock img {\n    margin-right: 16px;\n}\n.MapLink {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": "Footer_Footer__WiYT0",
	"MapBlock": "Footer_MapBlock__-GYmU",
	"MapLink": "Footer_MapLink__cf2lU"
};
export default ___CSS_LOADER_EXPORT___;
