// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BestRatedBooks_BestRatedBooks__syOlN h2 {\n    font-family: \"Libre Baskerville\", serif;\n    font-size: 34px;\n    font-style: italic;\n    color: #796157;\n    margin-bottom: 2em;\n}\n\n.BestRatedBooks_List__GJ7-T {\n    display: flex;\n    justify-content: space-evenly;\n    flex-wrap: wrap;\n}", "",{"version":3,"sources":["webpack://./src/components/Books/BestRatedBooks/BestRatedBooks.module.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,eAAe;AACnB","sourcesContent":[".BestRatedBooks h2 {\n    font-family: \"Libre Baskerville\", serif;\n    font-size: 34px;\n    font-style: italic;\n    color: #796157;\n    margin-bottom: 2em;\n}\n\n.List {\n    display: flex;\n    justify-content: space-evenly;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BestRatedBooks": "BestRatedBooks_BestRatedBooks__syOlN",
	"List": "BestRatedBooks_List__GJ7-T"
};
export default ___CSS_LOADER_EXPORT___;
